.paddings {
  padding: 1.5rem;
}
.innerWidth {
  width: 100%;
}

.flexCenter {
  display: flex;
  row-gap: 2rem;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}
.flexColStart {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}
.container {
  overflow: hidden;
  position: relative;
}
.head {
  margin-bottom: 2rem;
}
.card {
  gap: 0.6rem;
  padding: 1rem;
  border-radius: 10px;
  max-width: max-content;
  margin: auto;
  transition: all 300ms ease-in;

  > img {
    width: 100%;
    height: 150px;
    max-width: 15rem;
    border-radius: 10px;
  }

  > :nth-child(2) {
    font-size: 1.2rem;
    font-weight: 600;
  }
  > :nth-child(3) {
    font-size: 1.5rem;
  }
  > :nth-child(4) {
    font-size: 0.7rem;
    width: 15rem;
  }

  &:hover {
    scale: 1.025;
    cursor: pointer;
    background: linear-gradient(
      180deg,
      rgba(255, 255, 255, 0) 0%,
      rgba(136, 160, 255, 0.46) 217.91%
    );

    box-shadow: 0px 72px 49px -51px rgba(136, 160, 255, 0.21);
  }
}

.r-buttons {
  position: absolute;
  gap: 0.5rem;
  top: -4rem;
  right: 0;

  button {
    font-size: 1.2rem;
    padding: 0.2rem 0.8rem;
    color: blue;
    // border: none;
    border-radius: 5px;
    border: 0.2px solid rgba(0, 0, 0, 0.25);
    background-color: transparent;
    cursor: pointer;

    &:hover {
      border: none;
      box-shadow: 0 0 5px 1px rgba(0, 0, 0, 0.25);
    }
  }

  // > :nth-child(1) {
  //   background-color: #eeeeff;
  // }
  // background-color: #eeeeff;

  // > :nth-child(2) {
  //   box-shadow: 0 0 5px 1px rgba(0, 0, 0, 0.25);
  // }
}

@media (min-width: 1536px) {
  .innerWidth {
    max-width: 1280px;
    margin: auto;
  }
}
@media (min-width: 1280px), (min-width: 768px) {
  .paddings {
    padding: 2rem;
  }
}

@media (min-width: 640px) {
  .paddings {
    padding: 4rem;
  }
}
@media (max-width: 640px) {
  .head {
    align-items: center;
  }
  .r-buttons {
    // position: initial;
    display: none;
  }
}
