.paddings {
  padding: 1.5rem;
}
.innerWidth {
  width: 100%;
}

.flexCenter {
  display: flex;
  row-gap: 2rem;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}
.flexColStart {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}
.image-container {
  width: 30rem;
  height: 35rem;
  overflow: hidden;
  border-radius: 15rem 15rem 0 0;
  border: 8px solid rgb(232 232 232 / 93%);

  img {
    width: 100%;
    height: 100%;
  }
}

.container {
  > div {
    flex: 1;
    display: flex;
    justify-content: center;
  }
}

.right {
  gap: 0.5rem;
}
.icon {
  padding: 10px;
  background-color: #eeeeff;
  border-radius: 5px;

  svg {
    fill: var(--blue);
  }
}

@media (min-width: 1536px) {
  .innerWidth {
    max-width: 1280px;
    margin: auto;
  }
}

@media (min-width: 640px) {
  .paddings {
    padding: 4rem;
  }
}

@media (min-width: 1280px), (min-width: 768px) {
  .paddings {
    padding: 2rem;
  }
}
@media (max-width: 640px) {
  .image-container {
    width: 95%;
    height: 25rem;
  }
}

@media (max-width: 1024px) {
  .container {
    flex-direction: column;
  }

 
}
