.paddings {
  padding: 1.5rem;
}
.innerWidth {
  width: 100%;
}
.flexCenter {
  display: flex;
  row-gap: 2rem;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}

.h-wrapper {
  background: var(--black);
  color: white;
  z-index: 3;
  position: sticky;
  top: 0;
  // overflow: hidden;
}

.h-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 1rem;
  padding-bottom: 1rem;
  color: var(--secondary);
}

.h-menu {
  gap: 2rem;
  display: flex;
  row-gap: 2rem;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  z-index: 9;
  *:hover {
    cursor: pointer;
  }
}

.menu-icon {
  z-index: 8;
  display: none;
}

@media (min-width: 1536px) {
  .innerWidth {
    max-width: 1280px;
    margin: auto;
  }
}

@media (max-width: 768px) {
  .menu-icon {
    display: block;
  }
  .h-menu {
    color: var(--black);
    position: absolute;
    top: 3rem;
    right: 4rem;
    background: white;
    flex-direction: column;
    font-weight: 500;
    gap: 2rem;
    padding: 3rem;
    border-radius: 10px;
    align-items: flex-start;
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.05);
    transition: all 300ms ease-in;
    // z-index: -1;
  }
}

@media (min-width: 640px) {
  .paddings {
    padding: 4rem;
  }
}

@media (min-width: 1280px), (min-width: 768px) {
  .paddings {
    padding: 2rem;
  }
}