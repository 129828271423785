.flexCenter {
  display: flex;
  row-gap: 2rem;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}
.flexColStart {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}
.wrapper {
  color: var(--white);
  position: relative;
  padding-bottom: 2rem;
  background-color: var(--black);
  z-index: 1;
}

.container {
  justify-content: space-around;
  align-items: flex-end;
}

// right side

.image-container {
  width: 30rem;
  height: 35rem;
  overflow: hidden;
  border-radius: 15rem 15rem 0 0;
  border: 8px solid rgba(225, 255, 255, 0.12);

  img {
    width: 100%;
    height: 100%;
  }
}

// left side
.left {
  // max-width: 60%;
  gap: 3rem;

  .title {
    position: relative;
    z-index: 1;

    .orange-circle {
      height: 4rem;
      width: 4rem;
      background: var(--orange-gradient);
      border-radius: 50%;
      position: absolute;
      right: 28%;
      top: -10%;
      z-index: -1;
    }
    h1 {
      font-weight: 600;
      font-size: 3.8rem;
      line-height: 4rem;
    }
  }
}

.search-bar {
  background-color: white;
  border-radius: 5px;
  border: 3px solid rgba(120, 120, 120, 0.374);
  padding: 0.5rem 1rem;
  justify-content: space-between;
  width: 100%;

  input {
    border: none;
    outline: none;
    padding: 0 5px;
  }

  .icon-location {
    font-size: 22px;
    color: var(--blue);
  }
}

.starts {
  width: 100%;
  justify-content: space-between;

  .start > :nth-child(1) {
    font-size: 2rem;
  }
  .start > :nth-child(1) > :last-child {
    color: orange;
  }
}

@media (max-width: 640px) {
  .orange-circle {
    margin-top: 0.5rem;
  }
  .title {
    > h1 {
      font-size: 2.5rem;
      line-height: 3rem;
    }
  }

  .image-container {
    width: 95%;
    height: 25rem;
  }
  .starts {
    justify-content: center;
    gap: 1.5rem;
  }

  .right {
    width: 100%;
  }
  .start{
    >:nth-child(1){
      font-size: 1.5rem;
    }
    >:nth-child(2){
      font-size: 0.8rem;
    }
  }
}
