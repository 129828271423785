.wrapper {
  //   display: flex;
  //   flex-direction: column;
  //   align-items: center;
  // position: relative;
  // overflow-x: clip;

  // & > :nth-child(1) {
  //   background: var(--black);
  //   position: relative;
  // }'
overflow: hidden;
}

.white-gradient {
  position: absolute;
  width: 23rem;
  height: 23rem;
  background: rgba(255, 255, 255, 0.522);
  filter: blur(100px);
  border-radius: 100px;
  z-index: 4;
}
// .container {
//   // width: var(--default-layout-width, 1150px);
//   width: 100%;
//   display: flex;
// }
// .content {
//   flex: 1;
//   // display: flex;
//   // flex-direction: column;
//   // align-items: flex-start;
// }
