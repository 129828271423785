@import 'normalize.css';
// @import url('https://fonts.googleapis.com/css2?family=IBM+Plex+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;1,400;1,700&display=swap');
// @import url('https://fonts.googleapis.com/css2?family=IBM+Plex+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;1,400;1,700&family=Roboto:ital,wght@0,300;0,400;0,500;0,700;1,300;1,500;1,700&display=swap');
* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  scroll-behavior: smooth;
}

:root {
  --primary: #1f3e72;
  --secondary: rgba(255, 255, 255, 0.78);
  --black: #131110;
  --blue-gradient: linear-gradient(97.05deg, #4066ff 3.76%, #2949c6 100%);
  --orange-gradient: linear-gradient(270deg, #ffb978 0%, #ff922d 100%);
  --blue: #4066ff;
  --lightBlue: #eeeeff;
  --shadow: 0px 23px 21px -8px rgba(136, 160, 255, 0.25);

  --default-layout-header-width: 60px;
  --default-layout-width: 1150px;
  --default-layout-horizontal-spacer: 24px;
  // colors
  --black: #000;
  --white: #fff;
  --text-color: #161823;
}

html {
  // font-size: 62.5%;
}

body {
  // font-family: 'ProximaNova', sans-serif;
  font-family: 'Poppins', sans-serif;

  line-height: 1.5;
  // font-size: 1.6rem;
  text-rendering: optimizeSpeed;
  color: var(--text-color);
  overflow-y: overlay;
}

// scrollbar
html *::-webkit-scrollbar {
  border-radius: 0;
  width: 8px;
}
html *::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background-color: rgba(22, 24, 35, 0.06);
}
html *::-webkit-scrollbar-track {
  border-radius: 0;
  background-color: rgba(0, 0, 0, 0);
}

button,
input,
[tabindex] {
  outline: none;
  border: none;
}

a[href] {
  color: inherit;
  text-decoration: none;
}

// custom tippy tooltip
body {
  .tippy-box {
    border-radius: 8px;
    font-weight: 600;
    font-size: 1.6rem;
    line-height: 2.2rem;
    background-color: rgba(84, 84, 84, 092);
  }
  .tippy-arrow {
    color: rgba(84, 84, 84, 092);
  }

  .tippy-content {
    padding: 8px 9px;
  }
  // .tippy-box[data-placement='bottom'] > .tippy-arrow::before {
  //   top: -8;
  // }
}

// // Fonts embedded
// @font-face {
//   font-family: ProximaNova;
//   src: url('../assets/fonts/Proxima-Nova-Regular.woff2');
//   font-weight: 400;
// }
// @font-face {
//   font-family: ProximaNova;
//   src: url('../assets/fonts/Proxima-Nova-Semibold.woff2');
//   font-weight: 600;
// }
// @font-face {
//   font-family: ProximaNova;
//   src: url('../assets/fonts/Proxima-Nova-Semibold.woff2');
//   font-weight: 700;
// }
// @font-face {
//   font-family: SofiaPro;
//   src: url('../assets/fonts/sofiapro-bold.woff2');
//   font-weight: 700;
// }
// ----------------------------
.paddings {
  padding: 1.5rem;
}

.innerWidth {
  width: 100%;
}

.flexCenter {
  display: flex;
  row-gap: 2rem;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}
.flexStart {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.flexEnd {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.flexColCenter {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.flexColStart {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}
.flexColEnd {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
}

.primaryText {
  color: #1f3e72;
  font-weight: bold;
  font-size: 2rem;
}

.secondaryText {
  color: rgb(140 139 139);
  font-size: 0.8rem;
}
.orangeText {
  color: orange;
  font-size: 1.5rem;
  font-weight: 600;
}

.button {
  font-weight: 600;
  padding: 0.6rem 1.4em;
  color: var(--white);
  background: var(--blue-gradient);

  border: none;
  border-radius: 4px;
  transition: all 300ms ease-in;

  &:hover {
    cursor: pointer;
    transform: scale(1.1);
  }
}

/* media queries */
@media (min-width: 1536px) {
  .innerWidth {
    max-width: 1280px;
    margin: auto;
  }
}

@media (min-width: 640px) {
  .paddings {
    padding: 4rem;
  }
}

@media (min-width: 1280px), (min-width: 768px) {
  .paddings {
    padding: 2rem;
  }
}

@media (max-width: 640px) {
  .primaryText {
    font-size: 1.5rem;
  }
  .orangeText {
    font-size: 1.2rem;
  }
}

// slider style
.swiper-horizontal {
  overflow: visible;
}
.swiper-pagination {
  position: relative;
  width: 15rem !important;
  bottom: 1rem;
}
.swiper-pagination .swiper-pagination-bullet {
  filter: drop-shadow(0px 8px 24px rgba(18, 28, 53, 0.1));
}
.swiper-pagination .swiper-pagination-bullet-active {
  background: var(--primary);
}

//accordion

.accordion {
  margin-top: 2rem;
  border: none;
}
.accordionItem {
  background: white;
  border: 0.8px solid rgba(128, 128, 128, 0.143);
  border-radius: 8px;
  overflow: hidden;
  margin-bottom: 20px;

  .expanded {
    box-shadow: var(--shadow);
    border-radius: 6px;
  }
}
.accordionButton {
  display: flex;
  row-gap: 2rem;
  align-items: center;
  flex-wrap: wrap;
  background: white;
  padding: 1rem;
  width: 100%;
  justify-content: space-between;
  cursor: pointer;

  .primaryText {
    font-size: 1.1rem;
  }
}
@media (max-width: 768px) {
  .accordionButton .primaryText {
    font-size: .8rem;
  }
}
